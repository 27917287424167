<script lang="ts" setup>
import {z} from 'zod'

interface Model {
  customer?: string;
  phone?: string;
  email?: string;
}

const {customerRequestService} = useApi()
const toast = useToast()

const sent = ref(false)

const form = useFormContextModel<Model>({
  rules: {
    customer: z.string().max(50),
    phone: z
      .string()
      .startsWith('+7')
      .regex(
        /^\+7\s\d{3}\s\d{3}-\d{2}-\d{2}$/,
        'Неверный формат телефона'
      ),
    email: z.string().email(),
  },
  initial: {
    customer: undefined,
    phone: undefined,
    email: undefined,
  },
  onSubmit: submit,
})

async function submit(payload: Model) {
  try {
    await customerRequestService.createNewRequest(payload)
    sent.value = true
  } catch (e) {
    const error = useApiError(e)

    if (error.isValidationError) {
      form.setErrors(error.bag)

      return
    }

    toast.error('Не удалось отправить заявку, попробуйте позже')
  }
}
</script>

<template>
  <Card>
    <div class="flex flex-col w-full items-start justify-between gap-y-10">
      <div class="flex flex-col w-full gap-y-10">
        <TextHeading
          :level="2"
          :heading="false"
          class="w-3/4 xl:w-full"
        >
          Не нашли ничего интересного / есть вопросы?
        </TextHeading>

        <Card color="white" :waves="false" class="w-full max-w-4xl">
          <div v-if="!sent" class="flex flex-col gap-6 text-uk-blue">
            <TextSubtitle bold class="md:w-9/12 xl:w-7/12">
              Оставьте заявку - мы обсудим ваши цели и подберем
              решение.
            </TextSubtitle>

            <FormProvider @submit="form.submit" @reset="form.reset">
              <div
                class="flex flex-col xl:flex-row items-start justify-between gap-4 xl:gap-5"
              >
                <div
                  class="flex flex-col lg:flex-row lg:items-start gap-2 justify-between w-full"
                >
                  <FormGroup class="lg:w-3/12 xl:w-min">
                    <FormFieldText
                      v-bind="form.fields.customer"
                      name="username"
                      placeholder="Имя"
                      class="max-w-full xl:max-w-[150px] 2xl:max-w-none"
                    />
                    <FormError
                      :message="form.errors.customer"
                    />
                  </FormGroup>
                  <FormGroup class="lg:w-3/12 xl:w-min">
                    <FormFieldPhone
                      v-bind="form.fields.phone"
                      name="phone"
                      placeholder="Телефон"
                      class="max-w-full xl:max-w-[180px] 2xl:max-w-none"
                    />
                    <FormError
                      :message="form.errors.phone"
                    />
                  </FormGroup>
                  <FormGroup class="lg:w-5/12 xl:w-min">
                    <FormFieldText
                      v-bind="form.fields.email"
                      name="email"
                      placeholder="Эл. почта"
                      class="max-w-full xl:max-w-[190px] 2xl:max-w-none"
                    />
                    <FormError
                      :message="form.errors.email"
                    />
                  </FormGroup>
                </div>
                <ButtonColored
                  type="submit"
                  variant="primary-border"
                  class="w-full md:w-auto"
                >
                  Отправить
                </ButtonColored>
              </div>
            </FormProvider>

            <TextCaption class="text-uk-gray">
              Отправляя заявку, вы соглашаетесь с условиями
              <LinkText
                to="/policy"
                size="sm"
                class="underline inline-flex"
              >
                Обработки персональных данных
              </LinkText>
            </TextCaption>
          </div>

          <TextSubtitle
            v-else
            bold
            class="md:w-9/12 xl:w-7/12 text-uk-blue"
          >
            Спасибо! Ваша заявка отправлена. Мы скоро свяжемся с
            вами.
          </TextSubtitle>
        </Card>
      </div>
    </div>
  </Card>
</template>

<style scoped></style>
